const Prices = () => {
  return (
    <div className="min-h-screen p-8 bg-transparent">
      <h1 className="text-3xl font-bold mb-8 text-center">Hinnasto</h1>
      <div className="space-y-8 max-w-4xl mx-auto">
        <section className="p-4 bg-white shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-4">1. Jäsenmaksu</h2>
          <ul className="list-disc list-inside space-y-2 text-lg">
            <li className="line-through decoration-red-500">
              1 vuoden jäsenyys 39,90€ (sis. alv) + 20 krediittiä
            </li>
            <li className="text-green-500 underline">
              Nyt 3 vuoden jäsenyys ilmaiseksi!
            </li>
            <li>3 vuoden jäsenyys 99,90€ (sis. alv) + 50 krediittiä</li>
            <li>5 vuoden jäsenyys 149,90€ (sis. alv) + 75 krediittiä</li>
          </ul>
        </section>
        <section className="p-4 bg-white shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-4">2. Vaihtomaksu</h2>
          <ul className="list-disc list-inside space-y-2 text-lg">
            <li>
              Lomaviikon varauksesta peritään 129,00€ (sis. alv) vaihtomaksu +
              kyseisen lomaviikon krediitit. (näkyvät ilmoituksessa)
            </li>
            <li>
              Äkkilähdöt (4 viikkoa ennen loman alkua) = 99,90€ (sis. alv)
            </li>
            <li>
              Äkkilähtöviikkoja voi myös varata rajoituksetta menettämättä omia
              krediittejä maksamalla kiinteän viikkohinnan 99,90€ (sis. alv).
            </li>
          </ul>
        </section>

        <section className="p-4 bg-white shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold mb-4">
            3. Lisäkrediittien ostaminen
          </h2>
          <ul className="list-disc list-inside space-y-2 text-lg">
            <li>
              Mikäli lompakossa olevat krediitit eivät riitä valittuun
              lomaviikkoon, lisäkrediittejä voi ostaa varauksen yhteydessä.
            </li>
            <li>1€/krediitti (sis. alv)</li>
          </ul>
          <p className="text-lg mt-4">
            Esimerkki: <br />
            Jos varaat lomaviikon, joka maksaa 100 krediittiä, mutta sinulla on
            vain 80 krediittiä lompakossasi, voit ostaa 20 lisäkrediittiä
            hintaan 20€.
          </p>
        </section>

        <section className="p-4 bg-white shadow-lg rounded-lg">
          <h2 className="text-xl font-semibold my-2">Lisätietoa</h2>
          <ul className="list-disc list-inside space-y-2 text-lg">
            <li>
              Maksunvälityspalvelun toteuttajana ja maksupalveluntarjoajana
              toimii Paytrail Oyj (2122839-7) yhteistyössä suomalaisten pankkien
              ja luottolaitosten kanssa. Paytrail Oyj näkyy maksun saajana
              tiliotteella tai korttilaskulla ja välittää maksun kauppiaalle.
              Paytrail Oyj:llä on maksulaitoksen toimilupa.
              Reklamaatiotapauksissa pyydämme ottamaan ensisijaisesti yhteyttä
              tuotteen toimittajaan. Paytrail Oyj, y-tunnus: 2122839-7 Innova 2
              Lutakonaukio 7 40100 Jyväskylä
              paytrail.com/kuluttaja/tietoa-maksamisesta
            </li>
            <li>Sivustolla olevilla maksuilla ei ole palautusoikeutta.</li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default Prices;
