import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Arrow from "../assets/arrow.png";
import carousel1 from "../assets/carousel1.png";
import carousel2 from "../assets/carousel2.png";
import carousel3 from "../assets/carousel3.png";
import carousel4 from "../assets/carousel4.png";

const images = [carousel1, carousel2, carousel3, carousel4];

const ImageCarousel = () => {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 10000); // Auto slide every 5 seconds
    return () => clearInterval(interval);
  }, [index]);

  const nextSlide = () => {
    setDirection(1);
    setIndex((prev) => (prev === images.length - 1 ? 0 : prev + 1));
  };

  const prevSlide = () => {
    setDirection(-1);
    setIndex((prev) => (prev === 0 ? images.length - 1 : prev - 1));
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto h-[500px] overflow-hidden rounded-xl">
      {/* Image Transition with Rounded Corners */}
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={index}
          className="w-full h-full flex items-center justify-center rounded-xl"
          initial={{ opacity: 0, x: direction * 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -direction * 100 }}
          transition={{ duration: 0.5 }}
        >
          <img
            src={images[index]}
            alt={`Slide ${index + 1}`}
            className="w-full h-full object-fit rounded-xl"
          />
        </motion.div>
      </AnimatePresence>

      {/* Left Arrow */}
      <button
        onClick={prevSlide}
        className="absolute left-4 top-1/2 -translate-y-1/2 bg-white bg-opacity-40 p-2 rounded-full hover:bg-opacity-60 transition"
      >
        <img src={Arrow} alt="Previous" className="w-6 h-6 rotate-90" />
      </button>

      {/* Right Arrow */}
      <button
        onClick={nextSlide}
        className="absolute right-4 top-1/2 -translate-y-1/2 bg-white bg-opacity-40 p-2 rounded-full hover:bg-opacity-60 transition"
      >
        <img src={Arrow} alt="Next" className="w-6 h-6 -rotate-90" />
      </button>

      {/* Pagination Dots (Better visibility & spacing) */}
      <div className="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex space-x-3">
        {images.map((_, i) => (
          <button
            key={i}
            className={`w-4 h-4 rounded-full transition-all shadow-md ${
              i === index ? "bg-teal-600 scale-125" : "bg-gray-400"
            }`}
            onClick={() => setIndex(i)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageCarousel;
