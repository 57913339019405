import image from "../assets/card_placeholder.jpg";
import calendar from "../assets/calendar.png";
import bed from "../assets/bed.png";
import dollar from "../assets/dollar.png";
import pin from "../assets/location.png";
import cottage from "../assets/cottage.png";
import arrow from "../assets/arrow.png";
import sauna from "../assets/sauna.png";
import balcony from "../assets/balcony.png";
import paw from "../assets/paw.png";
import remove from "../assets/cross-circle.svg";
import Timer from "./Timer";
import { useState, useEffect } from "react";
import axios from "axios";

const Card = ({
  timeshare,
  buttonText,
  onClick,
  disabled,
  showTimer,
  offer,
  adjustedCredits,
  user,
}) => {
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const propertyName = timeshare.property_name || timeshare.propertyName;

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Fetch images for the property
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/timeshares/images/${propertyName}`
        );
        setImages(response.data);
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, [timeshare.propertyName]);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const getISOWeek = (date) => {
    const tempDate = new Date(date);
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));
    const week1 = new Date(tempDate.getFullYear(), 0, 4);
    return (
      1 +
      Math.round(
        ((tempDate.getTime() - week1.getTime()) / 86400000 -
          3 +
          ((week1.getDay() + 6) % 7)) /
          7
      )
    );
  };

  const deleteTimeshare = async (id) => {
    if (user.isAdmin) {
      try {
        const response = await axios.delete(
          `/api/timeshares/delete/${id}?isAdmin=true`,
          {
            withCredentials: true,
          }
        );

        const result = response.data;

        if (result.success) {
          console.log("Timeshare deleted successfully");
        } else {
          console.error("Failed to delete timeshare:", result.message);
        }
      } catch (error) {
        console.error("Error deleting timeshare:", error);
      }
    } else {
      console.warn("Unauthorized: Only admins can delete timeshares.");
    }
  };

  const weekNumber = timeshare.end_date ? getISOWeek(timeshare.end_date) : 0;

  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center">
        {showTimer ? (
          <>
            <div className="flex flex-row text-sm text-gray-500 font-bold">
              <Timer startDate={timeshare.start_date} />
            </div>
            <p className="text-sm text-red-500 font-bold ml-1">
              Huom! Saat enemmän krediittejä, mitä aiemmin talletat!
            </p>
          </>
        ) : null}
      </div>
      <div
        className="bg-white p-2 rounded-xl shadow-2xl w-80 m-4 hover:outline outline-teal-600 outline-2"
        style={{ transition: "transform 0.2s ease-in-out" }}
        onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.02)")}
        onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
      >
        <div className="flex flex-row">
          {timeshare.homepage_url ? (
            <a
              href={timeshare.homepage_url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-lg font-semibold hover:underline hover:cursor-pointer hover:text-teal-600 m-1 block"
              style={{ lineHeight: "1.2em" }} // Ensure consistent line-height
            >
              {propertyName || "ei nimeä"}
            </a>
          ) : (
            <h2
              className="text-lg font-semibold m-1 block"
              style={{ lineHeight: "1.2em" }} // Ensure consistent line-height
            >
              {propertyName || "ei nimeä"}
            </h2>
          )}
          {user.isAdmin ? (
            <button onClick={() => deleteTimeshare(timeshare.unit_id)}>
              <img className="h-4 w-4" src={remove} alt="remove" />
            </button>
          ) : (
            <span />
          )}
        </div>

        <div className="image-gallery">
          {images.length > 0 ? (
            <div className="relative">
              {/* Display the current image */}
              <img
                src={`${apiUrl}/${images[currentImageIndex].image_url}`}
                alt={images[currentImageIndex].image_description}
                className="w-full h-48 object-cover rounded-lg"
              />

              {/* Previous button */}
              <button
                onClick={handlePrevious}
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-gray-200 text-gray-800 px-2 py-1 rounded-full bg-opacity-40"
              >
                <img src={arrow} alt="Previous" className="w-4 h-4 rotate-90" />
              </button>

              {/* Next button */}
              <button
                onClick={handleNext}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-200 text-gray-800 px-2 py-1 rounded-full bg-opacity-60"
              >
                <img src={arrow} alt="Next" className="w-4 h-4 -rotate-90" />
              </button>
            </div>
          ) : (
            // Placeholder image if no images are available
            <img
              src={image}
              alt="Placeholder"
              className="w-full h-48 object-cover rounded-lg"
            />
          )}
        </div>
        <div className="p-4">
          <div className="flex flex-row my-1">
            <img src={pin} alt="Location" className="w-4 h-4 mr-2" />
            <p className="text-sm text-black">
              {timeshare.location.charAt(0).toUpperCase() +
                timeshare.location.slice(1)}
            </p>
          </div>
          <div className="flex flex-row my-1">
            <img src={calendar} alt="Calendar" className="w-4 h-4 mr-2" />
            <p className="text-sm text-black">
              vk {weekNumber} /{" "}
              {timeshare.start_date
                ? new Date(timeshare.start_date).toLocaleDateString("fi-FI")
                : "N/A"}{" "}
              -{" "}
              {timeshare.end_date
                ? new Date(timeshare.end_date).toLocaleDateString("fi-FI")
                : "N/A"}
            </p>
          </div>
          <div className="flex flex-row my-1">
            <img src={cottage} alt="Cottage" className="w-4 h-4 mr-2" />
            <p className="text-sm text-black capitalize">{timeshare.size}</p>
          </div>
          <div className="flex flex-row my-1">
            <img src={bed} alt="Bed" className="w-4 h-4 mr-2" />
            <p className="text-sm text-black">{timeshare.sleeping_places}</p>
          </div>
          <div className="flex flex-row my-1">
            <img src={sauna} alt="sauna" className="w-4 h-4 mr-2" />
            <p className="text-sm text-black">
              {timeshare.sauna === 1 ? "Sauna" : "-"}
            </p>
          </div>
          <div className="flex flex-row my-1">
            <img src={balcony} alt="balcony" className="w-4 h-4 mr-2" />
            <p className="text-sm text-black">
              {timeshare.balcony_terrace === 1 ? "Parveke / Terassi" : "-"}
            </p>
          </div>
          <div className="flex flex-row my-1">
            <img src={paw} alt="paw" className="w-4 h-4 mr-2" />
            <p className="text-sm text-black">
              {timeshare.pets === 1 ? "Lemmikit sallittu" : "Ei lemmikkejä"}
            </p>
          </div>

          <hr className="h-0.5 my-4 border-t-0 bg-gray-400 dark:bg-white/10" />
          <div className="flex flex-row my-1">
            <img src={dollar} alt="Dollar" className="w-4 h-4 mr-2" />
            {offer && adjustedCredits < timeshare.credits ? (
              <div className="flex flex-col">
                {/* Show regular price with line-through */}
                <p className="text-sm text-teal-600 font-bold line-through">
                  {timeshare.credits} Lomakrediittiä {/* Regular price */}
                </p>
                {/* Show adjusted price for the offer */}
                <p className="text-sm text-red-600 font-bold">
                  Äkkilähtö, vain 99,90€!
                </p>
              </div>
            ) : (
              <p className="text-sm text-teal-600 font-bold py-1.5">
                {timeshare.credits} Lomakrediittiä {/* Show regular price */}
              </p>
            )}
          </div>

          <button
            className={`bg-teal-600 text-white rounded-lg p-2 w-full mt-4 hover:bg-teal-700 ${
              disabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={() => onClick(timeshare, adjustedCredits)} // Pass the adjusted credits
            disabled={disabled}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
