import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import whitelogo from "../../assets/logo_valk.png";
import hamburger from "../../assets/white-hamburger.png"; // Hamburger icon

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);

    if (!isMenuOpen) {
      document.body.style.overflow = "hidden"; // Disable body scrolling
    } else {
      document.body.style.overflow = ""; // Enable body scrolling
    }
  };

  const handleLogout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        withCredentials: true,
      });
      logout();
      navigate("/kirjaudu");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div
      className={`w-screen h-24 sm:h-24 md:h-32 lg:h-28 xl:h-32 2xl:h-32 3xl:h-32 flex flex-row justify-center items-center z-50 transition duration-300 ${"bg-teal-600"}`}
    >
      {/* Logo section */}
      <div className="flex flex-col lg:flex-row ">
        <div className="px-8 flex justify-start my-1 sm:ml-0 sm:justify-center items-center">
          <img
            src={whitelogo}
            alt="logo"
            className="w-56 sm:w-56 md:w-56 md:pt-2 lg:p-0 lg:w-64 xl:w-72 2xl:w-96 hover:cursor-pointer"
            onClick={() => navigate("/")}
          />
        </div>

        {/* Links for desktop */}
        <div className="hidden md:flex md:flex-row items-center justify-center">
          <a
            href="/"
            className={
              "m-4 transition duration-200 px-2 py-2 rounded-xl text-white hover:underline"
            }
          >
            Etusivu
          </a>
          <a
            href="/hinnat"
            className={
              "m-4 transition duration-200 px-2 py-2 rounded-xl text-white hover:underline"
            }
          >
            Hinnasto
          </a>
          <a
            href="/laskin"
            className={
              "m-4 transition duration-200 px-2 py-2 rounded-xl text-white hover:underline"
            }
          >
            Lomakrediittilaskuri
          </a>
          <a
            href="/kayttoehdot"
            className={
              "m-4 transition duration-200 px-2 py-2 rounded-xl text-white hover:underline"
            }
          >
            Käyttöehdot
          </a>
          <a
            href="/tuki"
            className={
              "m-4 transition duration-200 px-2 py-2 rounded-xl text-white hover:underline"
            }
          >
            Tuki
          </a>

          {/* User links */}
          {user ? (
            <>
              <a
                href="/profiili"
                className={
                  "m-4 transition duration-200 px-2 py-2 rounded-xl text-white hover:underline"
                }
              >
                Oma sivu
              </a>
              <a
                href="/lompakko"
                className={
                  "m-4 transition duration-200 px-2 py-2 rounded-xl text-white hover:underline"
                }
              >
                Lompakko: {user.credits}
              </a>

              {user.isAdmin && (
                <>
                  <a
                    href="/kayttajat"
                    className={
                      "m-4 transition duration-200 px-2 py-2 rounded-xl text-white hover:underline"
                    }
                  >
                    Käyttäjät
                  </a>
                  <a
                    href="/kuvat"
                    className={
                      "m-4 transition duration-200 px-2 py-2 rounded-xl text-white hover:underline"
                    }
                  >
                    Kuvat
                  </a>
                  <a
                    href="/linkit"
                    className={
                      "m-4 transition duration-200 px-2 py-2 rounded-xl text-white hover:underline"
                    }
                  >
                    Linkit
                  </a>
                  <a
                    href="/alekoodit"
                    className={
                      "m-4 transition duration-200 px-2 py-2 rounded-xl text-white hover:underline"
                    }
                  >
                    Alekoodit
                  </a>
                </>
              )}

              <button
                className={
                  "m-4 px-4 py-2 rounded-xl transition duration-200 text-white hover:underline"
                }
                onClick={handleLogout}
              >
                Kirjaudu ulos
              </button>
            </>
          ) : (
            <button
              className={
                "m-4 px-4 py-2 rounded-xl transition duration-200 text-white hover:underline"
              }
              onClick={() => navigate("/kirjaudu")}
            >
              Kirjaudu sisään
            </button>
          )}
        </div>
      </div>

      {/* Mobile elements remain unchanged */}
      {/* Menu Toggle Button for Mobile */}
      <button
        className={`py-4 md:hidden absolute top-4 right-4 focus:outline-none ${
          isScrolled ? "text-white" : "text-black"
        }`}
        onClick={toggleMenu}
      >
        <img src={hamburger} alt="menu" className="h-8 w-8" />
      </button>

      {/* Links for mobile */}
      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } absolute top-24 left-0 w-full h-screen bg-white md:hidden`}
      >
        {/* Make the menu content scrollable when it exceeds the screen height */}
        <div className="w-full flex flex-col max-h-screen overflow-y-auto">
          <a
            href="/"
            className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
            onClick={toggleMenu}
          >
            Etusivu
          </a>
          <a
            href="/hinnat"
            className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
            onClick={toggleMenu}
          >
            Hinnasto
          </a>
          <a
            href="/laskin"
            className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
            onClick={toggleMenu}
          >
            Lomakrediittilaskuri
          </a>
          <a
            href="/kayttoehdot"
            className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
            onClick={toggleMenu}
          >
            Käyttöehdot
          </a>
          <a
            href="/tuki"
            className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
            onClick={toggleMenu}
          >
            Tuki
          </a>

          {/* User links */}
          {user ? (
            <>
              <a
                href="/profiili"
                className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
                onClick={toggleMenu}
              >
                Oma sivu
              </a>

              <a
                href="/lompakko"
                className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
                onClick={toggleMenu}
              >
                Lompakko: {user.credits}
              </a>

              {user.isAdmin && (
                <>
                  <a
                    href="/kayttajat"
                    className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
                    onClick={toggleMenu}
                  >
                    Käyttäjät
                  </a>
                  <a
                    href="/kuvat"
                    className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
                    onClick={toggleMenu}
                  >
                    Kuvat
                  </a>
                  <a
                    href="/linkit"
                    className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
                    onClick={toggleMenu}
                  >
                    Linkit
                  </a>
                  <a
                    href="/alekoodit"
                    className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
                    onClick={toggleMenu}
                  >
                    Alekoodit
                  </a>
                </>
              )}

              <button
                className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
                onClick={() => {
                  handleLogout();
                  toggleMenu();
                }}
              >
                Kirjaudu ulos
              </button>
            </>
          ) : (
            <button
              className="m-4 text-black text-center text-lg px-4 py-2 hover:bg-gray-100"
              onClick={() => {
                navigate("/kirjaudu");
                toggleMenu();
              }}
            >
              Kirjaudu sisään
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
