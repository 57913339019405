import { useState, useEffect } from "react";
import axios from "axios";
import Card from "../components/Card";
import ConfirmationModal from "../components/ConfirmationModal";
import { useAuth } from "../context/authContext";
import TimeshareInfoInput from "../components/TimeshareInfoInput";
import { calculateAdjustedCredits } from "../utils/creditUtils";
import Modal from "../components/Modal";
import TermsModal from "../components/TermsModal";

const Profile = () => {
  const [timeShares, setTimeShares] = useState([]);
  const [rentedTimeShares, setRentedTimeShares] = useState([]);
  const [bookedTimeShares, setBookedTimeShares] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [selectedTimeshare, setSelectedTimeshare] = useState(null);
  const [adjustedCredits, setAdjustedCredits] = useState(0); // State to store adjusted credits
  const [bookedWeeks, setBookedWeeks] = useState([]);
  const [showTimeshareInput, setShowTimeshareInput] = useState(false);
  const [rciBlocked, setRciBlocked] = useState(false);
  const [showRCIModal, setShowRCIModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showSevenDaysWarning, setShowSevenDaysWarning] = useState(false);
  const [clearInput, setClearInput] = useState(false);
  const [timeshareInput, setTimeshareInput] = useState({
    place: "",
    property: "",
    startDate: "",
    endDate: "",
    rooms: "",
    sleeping: "",
    room: "",
    rci: "",
  });

  const { user, updateUserCredits } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (user && user.id) {
      fetchTimeshares();
      fetchRentedTimeshares();
      fetchBookedTimeshares();
      console.log("user", user);
    }
  }, [user, apiUrl]);

  const fetchTimeshares = () => {
    if (user && user.id) {
      axios
        .get(`${apiUrl}/timeshares/${user.id}`, {
          withCredentials: true,
        })
        .then((response) => {
          const filteredTimeshares = response.data.filter(
            (timeshare) =>
              timeshare.listed !== 1 && timeshare.status !== "booked"
          );
          setTimeShares(filteredTimeshares);
          console.log("timeshares", filteredTimeshares);
        })
        .catch((error) => {
          console.error("Error fetching timeshares:", error);
        });
    } else {
      console.error("User is not defined or missing id");
    }
  };

  const fetchRentedTimeshares = () => {
    if (user && user.id) {
      axios
        .get(`${apiUrl}/timeshares/${user.id}/booked-timeshares`, {
          withCredentials: true,
        })
        .then((response) => {
          setRentedTimeShares(response.data); // Store response data in rentedTimeShares state
        })
        .catch((error) => {
          console.error("Error fetching rented timeshares:", error);
        });
    } else {
      console.error("User is not defined or missing id");
    }
  };

  const fetchBookedTimeshares = () => {
    if (user && user.id) {
      axios
        .get(`${apiUrl}/timeshares/${user.id}/booked-by-user`, {
          withCredentials: true,
        })
        .then((response) => {
          console.log("booked timeshares", response.data);
          setBookedTimeShares(response.data); // Store response data in bookedTimeShares state
        })
        .catch((error) => {
          console.error("Error fetching booked timeshares:", error);
        });
    } else {
      console.error("User is not defined or missing id");
    }
  };

  useEffect(() => {
    if (user && user.id) {
      axios
        .get(`${apiUrl}/timeshares/booked/${user.id}`, {
          withCredentials: true, // Ensure credentials are included
        })
        .then((response) => {
          setBookedWeeks(response.data);
        })
        .catch((error) =>
          console.error("Error fetching booked timeshares:", error)
        );
    }
  }, [user, apiUrl]);

  const handleDepositClick = (timeshare, calculatedCredits) => {
    const currentDate = new Date();
    const startDateMinus7Days = new Date(
      new Date(timeshare.start_date).getTime() - 7 * 24 * 60 * 60 * 1000
    );

    if (currentDate >= startDateMinus7Days) {
      setShowSevenDaysWarning(true); // Show warning modal instead of alert
      return;
    }

    setSelectedUnitId(timeshare.unit_id);
    setSelectedTimeshare(timeshare);
    setAdjustedCredits(calculatedCredits); // Use the pre-calculated credits
    setShowModal(true);
  };

  const handleDepositConfirm = () => {
    if (!selectedTimeshare) return;

    setShowModal(false);

    axios
      .put(
        `${apiUrl}/timeshares/deposit/${selectedTimeshare.unit_id}`,
        {
          credits: adjustedCredits,
          user_id: user.id,
        },
        {
          withCredentials: true,
        }
      )
      .then(async (response) => {
        try {
          await sendDepositEmail({
            email: user.email,
            name: user.name,
            startDate: selectedTimeshare.start_date,
            property: selectedTimeshare.property_name,
            size: selectedTimeshare.size,
            sleepingPlaces: selectedTimeshare.sleeping_places,
            credits: adjustedCredits,
          });
          console.log("Deposit confirmation email sent successfully");
        } catch (error) {
          console.error("Failed to send deposit confirmation email:", error);
        }

        // Update the user credits and fetch timeshares again
        updateUserCredits();
        fetchTimeshares();
      })
      .catch((error) => console.error("Error depositing week:", error));
  };

  const formatDateToMySQL = (date) => {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const postTimeshare = () => {
    if (rciBlocked) {
      setShowRCIModal(true);
      return;
    }

    const startDate = new Date(timeshareInput.startDate);
    const endDate = new Date(timeshareInput.endDate);

    if (isNaN(startDate) || isNaN(endDate)) {
      console.error("Invalid date values provided");
      return;
    }

    const formattedStartDate = formatDateToMySQL(startDate);
    const formattedEndDate = formatDateToMySQL(endDate);
    const initialCredits = timeshareInput.credits.Krediitit;

    if (!initialCredits) {
      console.error("Invalid initial credits provided");
      return;
    }

    const formattedTimeshare = {
      user_id: user.id,
      property_name: timeshareInput.property || "Unknown Property",
      location: timeshareInput.place || "Unknown Location",
      unit_number: timeshareInput.room || "N/A",
      size: timeshareInput.rooms || "N/A",
      sleeping_places: timeshareInput.sleeping || "N/A",
      rci_points: timeshareInput.rci || "N/A",
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      credits: initialCredits,
      sauna: timeshareInput.sauna ? 1 : 0,
      balcony_terrace: timeshareInput.balcony_terrace ? 1 : 0,
      pets: timeshareInput.pets ? 1 : 0,
    };

    axios
      .post(`${apiUrl}/timeshares/`, formattedTimeshare, {
        withCredentials: true,
      })
      .then((response) => {
        const newTimeshareWithId = {
          ...formattedTimeshare,
          unit_id: response.data.timeshareId, // Add the `unit_id` from response
        };
        setTimeShares((prevTimeshares) => [
          ...prevTimeshares,
          newTimeshareWithId,
        ]);
        setShowTimeshareInput(false);
        setTimeshareInput({
          place: "",
          property: "",
          startDate: "",
          endDate: "",
          rooms: "",
          sleeping: "",
          room: "",
          rci: "",
        });
        setClearInput(true);
      })
      .catch((error) => console.error("Error adding timeshare:", error));
  };

  const updateTimeshare = (updatedTimeshare) => {
    setTimeshareInput(updatedTimeshare);
  };

  const sendDepositEmail = async ({
    email,
    name,
    startDate,
    property,
    size,
    sleepingPlaces,
    credits,
  }) => {
    try {
      const emailData = {
        email,
        name,
        startDate,
        property,
        size,
        sleepingPlaces,
        credits,
      };

      await axios.post(`${apiUrl}/emails/deposit-email`, emailData);
      console.log("Deposit email sent successfully");
    } catch (error) {
      console.error("Error sending deposit email:", error);
    }
  };

  const handleOpenTerms = () => setShowTermsModal(true);
  const handleCloseTerms = () => setShowTermsModal(false);

  const handleCloseSevenDaysWarning = () => {
    setShowSevenDaysWarning(false);
  };

  return (
    <div className="min-h-screen bg-transparent flex flex-col items-center p-4">
      <div className="bg-transparent py-4 md:px-12">
        {user ? (
          <>
            <div className="max-w-screen">
              <h1 className="text-3xl font-bold mb-4 text-center">
                Varaamasi viikot
              </h1>
              {bookedTimeShares.length === 0 ? (
                <p className="text-3xl font-bold mb-4 text-center">
                  Sinulla ei ole varattuja viikkoja.
                </p>
              ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-4 flex justify-center">
                  {bookedTimeShares.map((timeshare, index) => (
                    <div className="flex flex-col justify-center items-center">
                      <div className="flex flex-col bg-white rounded-lg shadow-lg p-4 mx-4">
                        <p className="font-bold">Omistajan yhteystiedot:</p>
                        <p>Nimi: {timeshare.owner_name}</p>
                        <p>Puhelin: {timeshare.owner_phone}</p>
                        <p>Sähköposti: {timeshare.owner_email}</p>
                      </div>
                      <Card
                        key={`${timeshare.unit_id}-${index}`}
                        timeshare={timeshare}
                        buttonText={"Varattu"}
                        disabled={true}
                        user={user}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="max-w-screen mt-8">
              <h1 className="text-3xl font-bold mb-4 text-center">
                Talletettavissa olevat viikkosi
              </h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-4 flex justify-center">
                {timeShares.length === 0 ? (
                  <p className="col-span-full text-center">Ei viikkoja</p>
                ) : (
                  timeShares.map((timeShare, index) => {
                    const calculatedCredits = calculateAdjustedCredits(
                      timeShare.start_date,
                      timeShare.credits
                    );

                    return (
                      <Card
                        key={`${timeShare.unit_id}-${index}`}
                        timeshare={{
                          ...timeShare,
                          credits: calculatedCredits,
                        }}
                        buttonText={"Talleta viikko"}
                        user={user}
                        onClick={() =>
                          handleDepositClick(timeShare, calculatedCredits)
                        }
                        showTimer={true}
                      />
                    );
                  })
                )}
              </div>
              {showModal && selectedTimeshare && (
                <ConfirmationModal
                  prompt={`Haluatko varmasti tallettaa tämän viikon ${adjustedCredits} lomakrediittiä vastaan?`}
                  onConfirm={handleDepositConfirm}
                  onCancel={() => setShowModal(false)}
                  checkBox={true}
                  openTermsModal={handleOpenTerms}
                />
              )}
            </div>

            <div className="max-w-screen mt-8">
              <h1 className="text-3xl font-bold mb-4 text-center">
                Sinulta varatut viikot
              </h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-4 flex justify-center">
                {rentedTimeShares.length === 0 ? (
                  <p className="col-span-full text-center">Ei viikkoja</p>
                ) : (
                  rentedTimeShares.map((timeShare, index) => {
                    const calculatedCredits = calculateAdjustedCredits(
                      timeShare.start_date,
                      timeShare.credits
                    );

                    return (
                      <div className="flex flex-col justify-center items-center space-y-4">
                        {/* Information Box */}
                        <div className="flex flex-col bg-white rounded-lg shadow-lg p-4 mx-4">
                          <p className="font-bold">Varaajan yhteystiedot:</p>
                          <p>Nimi: {timeShare.booker_name}</p>
                          <p>Puhelin: {timeShare.booker_phone}</p>
                          <p>Sähköposti: {timeShare.booker_email}</p>
                        </div>

                        {/* Card Component */}
                        <Card
                          key={`${timeShare.unit_id}-${index}`}
                          timeshare={{
                            ...timeShare,
                            credits: calculatedCredits,
                          }}
                          buttonText={"Varattu"}
                          user={user}
                          disabled={true}
                        />
                      </div>
                    );
                  })
                )}
              </div>
            </div>

            <Modal
              isVisible={showSevenDaysWarning}
              onClose={handleCloseSevenDaysWarning}
              children={
                "Et voi tallettaa viikkoa, jonka alkamiseen on alle 7 päivää."
              }
            />

            <div className="w-full max-w-screen mt-8">
              <h1 className="text-3xl font-bold mb-4 text-center">
                Lisää uusi viikko
              </h1>
              <div className="space-y-4 max-w-xl mx-auto w-full">
                <div className="bg-white rounded-lg shadow-lg px-20 py-4 md:p-4 w-full">
                  <TimeshareInfoInput
                    timeshare={timeshareInput}
                    updateTimeshare={updateTimeshare}
                    setRciBlocked={setRciBlocked}
                    clearInput={clearInput}
                  />
                  <Modal
                    isVisible={showRCIModal}
                    onClose={() => setShowRCIModal(false)}
                    children="Et voi tallettaa osaketta, joka on liitetty RCI-pisteisiin."
                  />
                </div>
                <div className="flex justify-center">
                  <button
                    className="bg-teal-600 hover:bg-teal-700 w-2/3 md:w-full sm:w-auto text-white py-2 px-4 rounded-xl"
                    onClick={postTimeshare}
                  >
                    Lisää uusi omistamasi osake
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <p>
            Sinun täytyy kirjautua sisään, niin pääset tarkastelemaan
            profiiliasi.
          </p>
        )}
      </div>
      <TermsModal isVisible={showTermsModal} onClose={handleCloseTerms} />
    </div>
  );
};

export default Profile;
